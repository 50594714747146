import { Ref } from '@vue/reactivity'
import { DefaultTable, DefaultTableExposed } from 'apptimizm-ui'
import { TableHeader } from 'apptimizm-ui/src/ui/default-table/default-table'
import axios from '@/axios'
import { ScannedProductsEntity, ScannedProducts } from './types'

interface TableFilter {
  vendorCode: Ref<string>
  barcode: Ref<string>
  zoneNumberStart: Ref<number>
  zoneNumberEnd: Ref<number>
  readonly tableParams: {
    [code: string]: string
  }
}

interface ITableProps {
  table: Ref<DefaultTableExposed>
  tableFilter: TableFilter
}

export default ({ table, tableFilter }: ITableProps) => {
  const scannedProductsMeta = new ScannedProductsEntity()

  const headers: TableHeader[] = [
    { name: 'Код товара', sort: 'product__vendor_code', minWidth: '200px' },
    { name: 'Зона', sort: 'task__zone__title', minWidth: '160px' },
    { name: 'Количество', sort: 'amount', minWidth: '160px' },
    { name: 'Штрих-код', sort: 'product__barcode', minWidth: '200px' },
    { name: 'Название товара', sort: 'product__title', minWidth: '540px' }
  ]

  const line = (item: ScannedProducts) => {
    return (
      <tr>
        <td>{item.product.vendorCode}</td>
        <td>{item.zone.name}</td>
        <td>{item.amount}</td>
        <td>{item.product.barcode}</td>
        <td>{item.product.title}</td>
      </tr>
    )
  }

  return (
    <div class='table-wrapper'>
      <DefaultTable
        axios={axios}
        endpoint={scannedProductsMeta.endpoint}
        defaultFilter={tableFilter.tableParams}
        ref={table}
        line={line}
        itemConverter={(v: ScannedProducts) => scannedProductsMeta.load(v)}
        headers={headers}
        responseItemsKey="results"
        responseTotalKey="count"
        paginationType="page"
        scrollPagination={true}
        perPage={20}
      />
    </div>
  )
}